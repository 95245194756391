import { Suspense, lazy, useEffect, useRef, startTransition } from "react";
import React from "react";

import items from "./GalleryList";
import { GalleryItemProps } from "./GalleryList";

import pixelArtButton from "../buttons/pixelart_button.gif";
import animatedButton from "../buttons/animated_button.gif";
import carcasoButton from "../buttons/Carcaso_Button.gif";
import twilightButton from "../buttons/Twilight_button_RISKY_alt_colours.gif";
import finalfantasyButton from "../buttons/final_fantasy_button.gif";
import yugiohButton from "../buttons/Yugioh_button.gif";

const GalleryItem = lazy(() => import("./GalleryItem"));
const startingYear = 2024;
let tag_pixelart = "Pixel Art";
let tag_carcaso = "Carcaso";
let tag_twilight = "Twilight";
let tag_ff14 = "FF14";
let tag_yugioh = "Yugioh";
let tag_animated = "Animated";
let tag_ss13 = "SS13";

function Gallery() {
  const [currentTag, setCurrentTag] = React.useState("");
  const [yearsToFilter, setYearsToFilter] = React.useState([startingYear]);
  const [numberOfItems, setNumberOfItems] = React.useState(9);

  function filterByTag(galleryItem: GalleryItemProps) {
    if (currentTag === "") {
      return galleryItem;
    }
    if (galleryItem.tags) {
      return galleryItem.tags.includes(currentTag);
    }
  }

  let filteredItems = items.filter(filterByTag).slice(0, numberOfItems);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && window.scrollY > 0) {
      startTransition(() => {
        setNumberOfItems((prev) => prev + 3);
      });
      }
    });

    if (lastItemRef.current) {
      observer.current.observe(lastItemRef.current);
    }

    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [filteredItems]);

  function resetNumberOfItems() {
    window.scrollTo(0, 0);
    startTransition(() => {
      setNumberOfItems(6);
    });
  }

  return (
    <div className="Gallery">
      <span className="GalleryButtonsContainer">
        <button
          className="GalleryButtons"
          onClick={() => {
            startTransition(() => {
              setCurrentTag("");
              setYearsToFilter([startingYear]); //Resets when the user clicks All so they don't load a million images
              resetNumberOfItems();
            });
          }}
          disabled={currentTag === ""}
        >
          All
        </button>
        <button
          className="GalleryButtons"
          style={{
            backgroundImage: `url(${pixelArtButton})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={() => {
            startTransition(() => {
              setCurrentTag(tag_pixelart);
              resetNumberOfItems();
            });
          }}
          disabled={currentTag === tag_pixelart}
        ></button>
        <button
          className="GalleryButtons"
          style={{
            backgroundImage: `url(${animatedButton})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={() => {
            startTransition(() => {
              setCurrentTag(tag_animated);
              resetNumberOfItems();
            });
          }}
          disabled={currentTag === tag_animated}
        ></button>
        <button
          className="GalleryButtons"
          style={{
            backgroundImage: `url(${carcasoButton})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={() => {
            startTransition(() => {
              setCurrentTag(tag_carcaso);
              resetNumberOfItems();
            });
          }}
          disabled={currentTag === tag_carcaso}
        ></button>
        <button
          className="GalleryButtons"
          style={{
            backgroundImage: `url(${twilightButton})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={() => {
            startTransition(() => {
              setCurrentTag(tag_twilight);
              resetNumberOfItems();
            });
          }}
          disabled={currentTag === tag_twilight}
        ></button>
        <button
          className="GalleryButtons"
          style={{
            backgroundImage: `url(${finalfantasyButton})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={() => {
            startTransition(() => {
              setCurrentTag(tag_ff14);
              resetNumberOfItems();
            });
          }}
          disabled={currentTag === tag_ff14}
        ></button>
        <button
          className="GalleryButtons"
          style={{
            backgroundImage: `url(${yugiohButton})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          onClick={() => {
            startTransition(() => {
              setCurrentTag(tag_yugioh);
              resetNumberOfItems();
            });
          }}
          disabled={currentTag === tag_yugioh}
        ></button>
      </span>
      <div className="GalleryImagesContainer">
        {filteredItems.map((item, index) => (
          <Suspense fallback={<div>Loading...</div>} key={index}>
            <GalleryItem
              text={item.text}
              imageSrc={item.imageSrc}
              youtubeLink={item.youtubeLink}
              linkText={item.linkText}
              audio={item.audio}
              audioCredit={item.audioCredit}
              tags={item.tags}
            />
          </Suspense>
        ))}
        <div ref={lastItemRef} />
      </div>
      {numberOfItems < items.length && (
        <button
          className="GalleryLoadMoreButton"
          onClick={() => {
            startTransition(() => {
              setNumberOfItems((prev) => prev + 3);
            });
          }}
        >
          Load More
        </button>
      )}
    </div>
  );
}

export default Gallery;
