import TraptrixAudio from "../mp3/traptrix.mp3";
import ChibiChabaAudio from "../mp3/chibichaba.mp3";
import ZuryAudio from "../mp3/Zuzu commentary.mp3";
import AriZonaAudio from "../mp3/Rizzler Endorses AriZona Tea.mp3";

import Album from "../assets/Album.png";
import anatomyPractice from "../assets/compressed/webp/anatomy practice march 6.webp";
import arcis from "../assets/compressed/webp/arcisx.webp";
import Asa from "../assets/compressed/webp/Carcaso/asacarcaso.webp";
import BaldursGate from "../assets/compressed/webp/baldur's gate.webp";
import Balthasar from "../assets/compressed/webp/Carcaso/Balthasar.webp";
import Banner from "../assets/compressed/webp/twitter banner.webp";
import Bard from "../assets/compressed/webp/Carcaso/BardCarcaso.webp";
import Bee from "../assets/compressed/webp/beelady.webp";
import beeGirl from "../assets/beegirlpngtuber.gif";
import beloved from "../assets/compressed/Beloved.png";
import Blievois from "../assets/Blievois.gif";
import BluesClues from "../assets/Columbo x Blues Clues.gif";
import Boann from "../assets/Boann.gif";
import carbuncle from "../assets/compressed/webp/carbuncle.webp";
import CarcasoanGrafiti from "../assets/compressed/webp/carcasoan graffiti.webp";
import CarcasoHalloween from "../assets/compressed/webp/carcaso pumpkin.webp";
import cel from "../assets/CheVeun.png";
import cheveunMementoCaller from "../assets/compressed/webp/memento caller che'veun.webp";
import Chess from "../assets/compressed/webp/Ecumenical.webp";
import cherryBomb from "../assets/compressed/webp/cherrybomb.webp";
import ChrisKuaLeague from "../assets/compressed/webp/chris kua.webp";
import Chriskua from "../assets/compressed/webp/Carcaso/ChrisKuaFinalCarcaso.webp";
import chocobo from "../assets/compressed/webp/chocobo.webp";
import combination from "../assets/compressed/webp/Combination june 13 2023.webp";
import Damnatio from "../assets/compressed/webp/damnatio.webp";
import Dandelion from "../assets/compressed/webp/Carcaso/NilesCarcasoFinal.webp";
import DayndalChosen from "../assets/compressed/webp/Dayndal Wanderer.webp";
import Deus from "../assets/compressed/webp/Carcaso/Deus.webp";
import Ellysa from "../assets/compressed/webp/Carcaso/Ellysa.webp";
import Elroscian from "../assets/compressed/webp/Elroscian.webp";
import Fenmes from "../assets/compressed/webp/Carcaso/Fenmes.webp";
import Fighter from "../assets/compressed/webp/Carcaso/FighterCarcaso.webp";
import Fishermen from "../assets/compressed/webp/fishermen.webp";
import Gelu from "../assets/compressed/webp/Gelu_ALL.webp";
import Gloreya from "../assets/compressed/webp/Gloreya.webp";
import Goo from "../assets/compressed/Slimegorl.png";
import gonegirl from "../assets/compressed/webp/Carcaso/gonegirl.webp";
import infinite from "../assets/jinfinfinfif 2.gif";
import IrvanRedesign from "../assets/compressed/webp/Irvan Dion.webp";
import irvan_doctor from "../assets/irvan doctor.gif";
import IrvanTarot from "../assets/compressed/webp/irvan.webp";
import irvanpissed from "../assets/compressed/webp/irvanpissed.webp";
import Isaakios from "../assets/compressed/webp/Iskayois.webp";
import Ivy from "../assets/compressed/webp/Ivy.webp";
import Jinxie from "../assets/compressed/webp/Carcaso/Jinxxie2.webp";
import Kallgorelie from "../assets/compressed/webp/Carcaso/Kallgorelie.webp";
import Karro from "../assets/Karro.gif";
import Ko from "../assets/Ko.gif";
import Kris from "../assets/compressed/webp/Carcaso/ChrisJamesCarcaso.webp";
import Leslie from "../assets/compressed/webp/Leslie.webp";
import Leo from "../assets/compressed/webp/Carcaso/LeoCarcaso.webp";
import Lucy from "../assets/compressed/webp/lucille s.webp";
import Luke from "../assets/compressed/webp/Luke.webp";
import LunariTears from "../assets/compressed/webp/lunari Tears.webp";
import Maidcore from "../assets/compressed/webp/Maidcore.webp";
import Malacruz from "../assets/compressed/webp/TayroRegion.webp";
import moogle from "../assets/compressed/webp/moogle.webp";
import NeonWhite from "../assets/compressed/webp/neon white vmeets ss13.webp";
import nidalee from "../assets/compressed/webp/nidalee.webp";
import nirvan from "../assets/compressed/webp/nirvan.webp";
import Novisept from "../assets/compressed/webp/Carcaso/Novisept.webp";
import Nunn from "../assets/compressed/webp/Nunn.webp";
import Nyla from "../assets/Nyla.gif";
import Orello from "../assets/compressed/webp/Carcaso/Orello.webp";
import Overlay from "../assets/compressed/webp/Overlay.webp";
import Paul from "../assets/compressed/webp/Paul Finished.webp";
import PaulTokenStamp from "../assets/compressed/webp/Paul Token.webp";
import Peter from "../assets/compressed/webp/Carcaso/PeterInCarcaso.webp";
import pokeball from "../assets/compressed/webp/pokeball.webp";
import Qale from "../assets/compressed/webp/Qale_Final.webp";
import Rab from "../assets/compressed/webp/rab bunnoi.webp";
import Rat from "../assets/compressed/webp/Carcaso/ratjacket.webp";
import RatLamp from "../assets/compressed/webp/Carcaso/Ajax of Carcaso.webp";
import Ravv from "../assets/compressed/webp/ravv.webp";
import refugerock from "../assets/compressed/webp/refugerock.webp";
import rizzler from "../assets/compressed/webp/rizzler.webp";
import Robyn from "../assets/compressed/webp/Robyn.webp";
import Sable from "../assets/compressed/webp/sab.webp";
import SamJones from "../assets/compressed/webp/sam jones.webp";
import SilfaFull from "../assets/compressed/webp/Silfa FF OC.webp";
import softGoth from "../assets/compressed/webp/soft goth beach.webp";
import Starfinder1 from "../assets/compressed/webp/Starfinder Sketch.webp";
import starfinder from "../assets/compressed/Starfinder_Sprites.png";
import styleStudy from "../assets/compressed/webp/sutyd march 7 .webp";
import TammyPixelArt from "../assets/tammypixelart.png";
import tamiShiva from "../assets/compressed/webp/tami shiva.webp";
import TolpaJam from "../assets/Tolpa_Jam.gif";
import tolpaMental from "../assets/compressed/webp/tolpa mental.webp";
import tolpaPngtuber from "../assets/tolpa pngtuber.gif";
import Tolpa_Subway from "../assets/compressed/webp/tolpa subway.gif";
import TolpaYeah from "../assets/compressed/webp/Yeah.webp";
import Tolpacore from "../assets/Landscape Tolpa.gif";
import Travers from "../assets/compressed/webp/traversart.webp";
import Traptrix from "../assets/compressed/webp/profilepics/traptrix garden.webp";
import trophy from "../assets/compressed/webp/trophy.webp";
import tsciiker from "../assets/compressed/webp/Tsciiker.webp";
import twilightWizard from "../assets/compressed/webp/twilight wizard.webp";
import Vampire from "../assets/compressed/webp/Carcaso/vampire w turtleneck.webp";
import Vitriol from "../assets/compressed/webp/Carcaso/Vitriol.webp";
import Von from "../assets/compressed/webp/Von_God.webp";
import VonElden from "../assets/compressed/webp/von.webp";
import wilsonPokemon from "../assets/compressed/webp/Wilsonrealfuck.webp";
import Yaz from "../assets/compressed/webp/yazzzy.webp";
import yoyo from "../assets/compressed/webp/yoyo.webp";
import yuyuWatch from "../assets/compressed/webp/Yuyu_Watch_Final.webp";
import yuyuuu from "../assets/compressed/webp/yuyyyu.webp";
import Yuyu from "../assets/compressed/webp/Yuyu birthday drawing actually.webp";
import Zemeradi from "../assets/compressed/webp/zemeradi twilight.webp";

interface GalleryItemProps {
  text: string;
  imageSrc?: string;
  youtubeLink?: string;
  linkText?: string;
  audio?: string;
  audioCredit?: string;
  year?: number;
  tags?: Array<String>;
}

const tag_pixelart = "Pixel Art";
const tag_carcaso = "Carcaso";
const tag_twilight = "Twilight";
const tag_ff14 = "FF14";
const tag_yugioh = "Yugioh";
const tag_animated = "Animated";
const tag_ss13 = "SS13";

const tag_chara_tolpa = "Tolpa";

const items: GalleryItemProps[] = [
  {
    text: "All Silver Wake",
    imageSrc: LunariTears,
    tags: [tag_carcaso],
    year: 2024,
  },
  {
    text: "the CTA",
    imageSrc: CarcasoanGrafiti,
    tags: [tag_carcaso],
    year: 2024,
  },
  {
    text: "혜진",
    imageSrc: Novisept,
    tags: [tag_carcaso],
    year: 2024,
  },
  {
    text: "The Great Pumpking",
    imageSrc: CarcasoHalloween,
    tags: [tag_carcaso],
    year: 2024,
  },
  {
    text: "Deft Hand",
    imageSrc: Luke,
    tags: [tag_carcaso],
    year: 2024,
  },
  {
    text: "Orello, the lone Starbird of his Murmuring",
    imageSrc: Orello,
    tags: [tag_carcaso],
    year: 2024,
  },
  {
    text: "Kallgorelie",
    imageSrc: Kallgorelie,
    tags: [tag_carcaso],
    year: 2024,
  },
  {
    text: "Gelu",
    imageSrc: Gelu,
    tags: [tag_ff14, tag_pixelart],
    year: 2024,
  },
  {
    text: "Traptrix Sera",
    imageSrc: Traptrix,
    tags: [tag_yugioh],
    audio: TraptrixAudio,
    audioCredit: "Torch",
    year: 2024,
  },
  {
    text: "Tami's Shiva Cosplay!",
    imageSrc: tamiShiva,
    tags: [tag_ff14],
    year: 2024,
  },
  {
    text: "Tsciiker Welders",
    imageSrc: tsciiker,
    tags: [tag_twilight],
    year: 2024,
  },
  {
    text: "POV you ask Botany for your chem dispenser back",
    imageSrc: irvanpissed,
    tags: [tag_ss13],
    year: 2024,
  },
  {
    text: "Rehab",
    imageSrc: tolpaMental,
    tags: [tag_chara_tolpa],
    year: 2024,
  },
  {
    text: "Von's Elden Ring Character",
    imageSrc: VonElden,
    tags: [],
    year: 2024,
  },
  {
    text: "YingYu",
    imageSrc: yoyo,
    tags: [],
    year: 2024,
  },
  {
    text: "infinfinfinfsdgnasfnisognifnn",
    imageSrc: infinite,
    youtubeLink: "https://www.youtube.com/watch?v=tz80fAf3aJg",
    linkText: "Music",
    tags: [tag_animated, tag_ff14],
    year: 2024,
  },
  {
    text: "Tolpa Yeah!!",
    imageSrc: TolpaYeah,
    tags: [tag_pixelart, tag_chara_tolpa],
    year: 2024,
  },
  {
    text: "Leslie: God-King of Master Chef Season 5, the Silver Fox of Malibu",
    imageSrc: Leslie,
    tags: [],
    year: 2024,
  },
  {
    text: "Rabs",
    imageSrc: Rab,
    tags: [],
    year: 2024,
  },
  {
    text: "Refuge Rock",
    imageSrc: refugerock,
    tags: [tag_pixelart, tag_twilight],
    year: 2024,
  },
  {
    text: "nIRVANa",
    imageSrc: nirvan,
    tags: [],
    year: 2024,
  },
  {
    text: "Tolpa Jam",
    imageSrc: TolpaJam,
    tags: [tag_animated, tag_pixelart, tag_chara_tolpa],
    year: 2024,
  },
  {
    text: "Che'Veun and their Memento Caller",
    imageSrc: cheveunMementoCaller,
    tags: [tag_twilight],
    year: 2024,
  },
  {
    text: "Tolpa PNGtuber",
    imageSrc: tolpaPngtuber,
    tags: [tag_animated, tag_pixelart, tag_chara_tolpa],
    year: 2024,
  },
  {
    text: "Che'Veun the Master of Arms",
    imageSrc: cel,
    tags: [tag_twilight, tag_pixelart],
    year: 2024,
  },
  {
    text: "Yuyu in Carcaso but also more Disco Elysium practice",
    imageSrc: yuyuuu,
    tags: [tag_carcaso],
    year: 2024,
  },
  {
    text: "Rhila & Ravv",
    imageSrc: Goo,
    tags: [tag_pixelart, tag_twilight],
    year: 2024,
  },
  {
    text: "Some of my favourite things",
    imageSrc: beloved,
    tags: [tag_pixelart, tag_ff14],
    year: 2024,
  },
  {
    text: "Starfinder Crew",
    imageSrc: starfinder,
    youtubeLink: "https://www.youtube.com/watch?v=wEm6CY1QgLI",
    linkText: "Speedpaint",
    tags: [tag_pixelart],
    year: 2024,
  },
  {
    text: "Kingdom Hearts Watch Commission",
    imageSrc: yuyuWatch,
    youtubeLink: "https://www.youtube.com/watch?v=v8AK3gAEMQo&",
    linkText: "Speedpaint",
    tags: [tag_ff14, tag_pixelart],
    year: 2024,
  },
  {
    text: "Tolpa Returns",
    imageSrc: Album,
    youtubeLink: "https://www.youtube.com/watch?v=KGWw76t6qY8&",
    linkText: "Speedpaint",
    tags: [tag_pixelart, tag_chara_tolpa],
    year: 2024,
  },
  {
    text: "Spotify Stream Overlay",
    imageSrc: Overlay,
    youtubeLink: "https://www.youtube.com/watch?v=KGWw76t6qY8&",
    linkText: "Speedpaint",
    tags: [tag_pixelart, tag_chara_tolpa],
    year: 2024,
  },
  {
    text: "Damnatio Memoriae",
    imageSrc: Damnatio,
    tags: [tag_twilight],
    year: 2024,
  },
  {
    text: "Sable",
    imageSrc: Sable,
    tags: [],
    year: 2024,
  },
  {
    text: "Neon White",
    imageSrc: NeonWhite,
    tags: [],
    year: 2024,
  },
  {
    text: "Irvan Tarot",
    imageSrc: IrvanTarot,
    tags: [],
    year: 2024,
  },
  {
    text: "Chris Kua Appreciation",
    imageSrc: ChrisKuaLeague,
    tags: ["League"],
    year: 2024,
  },
  {
    text: "POV you're Rizzler's AriZona Tea",
    imageSrc: rizzler,
    tags: [],
    year: 2024,
    audio: AriZonaAudio,
    audioCredit: "the Rizzler",
  },
  {
    text: "Nidalee Pro Golfing",
    imageSrc: nidalee,
    tags: ["League"],
    year: 2024,
  },
  /*
  {
    text: "Lucille sketch",
    imageSrc: Lucy,
    tags: [],
    year: 2024,
  },
  {
    text: "Yazzy quick Sketch",
    imageSrc: Yaz,
    tags: [],
    year: 2024,
  },
  {
    text: "Style Study",
    imageSrc: styleStudy,
    tags: ["Study"],
    year: 2024,
  },
  {
    text: "Anatomy Practice",
    imageSrc: anatomyPractice,
    tags: ["Study"],
    year: 2024,
  },
  {
    text: "Soft Goth anatomy study",
    imageSrc: softGoth,
    tags: ["Study", ],
    year: 2024,
  },
  {
    text: "Arcis",
    imageSrc: arcis,
    tags: [],
    year: 2024,
  },
  */
  {
    text: "Pixel art Ko",
    imageSrc: Ko,
    youtubeLink:
      "https://www.youtube.com/watch?v=-QMUnTS6tlk&ab_channel=Taming",
    linkText: "Speedpaint",
    tags: [tag_twilight, tag_pixelart, tag_animated],
    year: 2024,
  },
  {
    text: "Four Knights Game",
    imageSrc: Chess,
    tags: [tag_carcaso],
    year: 2024,
  },
  {
    text: "Graffiti of a forgotten heartbreak that never happened",
    youtubeLink: "CzWqI3g08TI",
    tags: [tag_animated, tag_chara_tolpa],
    year: 2024,
  },
  {
    text: "Yuyu in Carcaso",
    imageSrc: Yuyu,
    tags: [tag_carcaso],
    year: 2024,
  },
  {
    text: "Irvan Animation",
    imageSrc: irvan_doctor,
    tags: [tag_animated],
    year: 2024,
  },
  {
    text: "Pixel art Nyla",
    imageSrc: Nyla,
    youtubeLink:
      "https://www.youtube.com/watch?v=Rrm3AzPYgPQ&ab_channel=Taming",
    linkText: "Speedpaint",
    tags: [tag_twilight, tag_pixelart, tag_animated],
    year: 2024,
  },
  {
    text: "Pixel art Ravv",
    imageSrc: Ravv,
    youtubeLink: "https://www.youtube.com/watch?v=esEzYmNSybc",
    linkText: "Speedpaint",
    tags: [tag_twilight, tag_pixelart],
    year: 2024,
  },
  {
    text: "Irvan Redesign",
    imageSrc: IrvanRedesign,
    year: 2024,
  },
  {
    text: "Dayndal's Chosen",
    imageSrc: DayndalChosen,
    tags: [tag_twilight],
    year: 2023,
  },
  {
    text: "Ivy & Veda Commission",
    imageSrc: Ivy,
    year: 2023,
  },
  {
    text: "Pixel art Dayndal",
    imageSrc: Von,
    tags: [tag_twilight, tag_pixelart],
    year: 2023,
  },
  {
    text: "Pixel art Karro",
    imageSrc: Karro,
    youtubeLink: "https://www.youtube.com/watch?v=T5NSXRj7wWU",
    linkText: "Speedpaint",
    tags: [tag_twilight, tag_pixelart, tag_animated],
    year: 2023,
  },
  {
    text: "Pixel art Blievois",
    imageSrc: Blievois,
    tags: [tag_twilight, tag_pixelart, tag_animated],
    year: 2023,
  },
  {
    text: "Silfa FF14 Full Commission",
    imageSrc: SilfaFull,
    tags: [tag_ff14],
    year: 2023,
  },
  {
    text: "Paul Token",
    imageSrc: PaulTokenStamp,
    year: 2023,
  },
  {
    text: "EU4 Fanart",
    imageSrc: Isaakios,
    year: 2023,
  },
  {
    text: "Tolpacore",
    // imageSrc: Tolpacore,
    youtubeLink: "aNY-jQEBajk",
    linkText: "Animated Music Video",
    tags: [tag_animated, tag_chara_tolpa],
    year: 2023,
  },
  {
    text: "Pixel art Boann",
    imageSrc: Boann,
    tags: [tag_twilight, tag_pixelart, tag_animated],
    year: 2023,
  },
  {
    text: "Paul Servius Maximus V the greatest Mailman",
    imageSrc: Paul,
    year: 2023,
  },
  /* {
      text: "Uncropped Robyn",
      imageSrc: Robyn,
    }, */
  {
    text: "Pixel art Gloreya",
    imageSrc: Gloreya,
    tags: [tag_twilight, tag_pixelart],
    year: 2023,
  },
  {
    text: "Pixel art Qale",
    imageSrc: Qale,
    tags: [tag_twilight, tag_pixelart],
    year: 2023,
  },
  {
    text: "Memento Reelers",
    imageSrc: Fishermen,
    tags: [tag_twilight],
    year: 2023,
  },
  {
    text: "Cyberpunk Red Maid",
    imageSrc: Maidcore,
    tags: [tag_chara_tolpa],
    year: 2023,
  },
  {
    text: "Twilight Zemeradi",
    imageSrc: Zemeradi,
    tags: [tag_twilight],
    year: 2023,
  },
  {
    text: "Baldur's Gate Paladin",
    imageSrc: BaldursGate,
    year: 2023,
  },
  {
    text: "Carcasoan Vitiligo",
    imageSrc: Elroscian,
    tags: [tag_carcaso],
    year: 2023,
  },
  {
    text: "Sam Jones",
    imageSrc: SamJones,
    year: 2023,
  },
  {
    text: "Sayuu's PNGtuber",
    imageSrc: beeGirl,
    tags: [tag_animated],
    year: 2023,
  },
  {
    text: "Hatching practice",
    imageSrc: combination,
    year: 2023,
  },
  {
    text: "Irvan the Shirren Moth",
    imageSrc: Starfinder1,
    year: 2023,
  },
  {
    text: "Twilight Wizards",
    imageSrc: twilightWizard,
    tags: [tag_twilight],
    year: 2023,
  },
  {
    text: "Nunn but Evangelion",
    imageSrc: Nunn,
    tags: [tag_ff14],
    year: 2023,
    audio: ZuryAudio,
    audioCredit: "Zury",
  },
  {
    text: "Columbo Meets Blue's Clues",
    imageSrc: BluesClues,
    tags: [tag_animated],
    year: 2023,
  },
  {
    text: "Tami in style of Fire Emblem",
    imageSrc: TammyPixelArt,
    tags: [tag_ff14, tag_pixelart],
    year: 2022,
  },
  {
    text: "City life in friend's pokemon world",
    imageSrc: Malacruz,
    year: 2022,
  },
  {
    text: "Tami with my favourite Yu-Gi-Oh archetypes",
    imageSrc: Banner,
    tags: [tag_yugioh, tag_ff14],
    year: 2022,
  },
  {
    text: "Kua in Carcaso",
    imageSrc: Chriskua,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Steel Dandelion",
    imageSrc: Dandelion,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Jinxxie in Carcaso",
    imageSrc: Jinxie,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Chris in Carcaso",
    imageSrc: Kris,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Peter in Carcaso",
    imageSrc: Peter,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "We Must Dissent",
    imageSrc: Rat,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Asa in Carcaso",
    imageSrc: Asa,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Vampire Turtleneck",
    imageSrc: Vampire,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Ajax",
    imageSrc: RatLamp,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Balthasar",
    imageSrc: Balthasar,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Bard",
    imageSrc: Bard,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Deus",
    imageSrc: Deus,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Ellysa",
    imageSrc: Ellysa,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Divine Order",
    imageSrc: Fighter,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Leo",
    imageSrc: Leo,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Saint Fenmes",
    imageSrc: Fenmes,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Gone Girl",
    imageSrc: gonegirl,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Cleansing",
    imageSrc: Vitriol,
    tags: [tag_carcaso],
    year: 2022,
  },
  {
    text: "Lala Carbuncle Badge",
    imageSrc: carbuncle,
    tags: [tag_ff14],
    year: 2022,
  },
  {
    text: "Lala Cherry Bomb Badge",
    imageSrc: cherryBomb,
    tags: [tag_ff14],
    year: 2022,
  },
  {
    text: "Lala Chocobo Badge",
    imageSrc: chocobo,
    tags: [tag_ff14],
    year: 2022,
  },
  {
    text: "Lala Moogle Badge",
    imageSrc: moogle,
    tags: [tag_ff14],
    year: 2022,
  },
  {
    text: "Lala Pokeball",
    imageSrc: pokeball,
    tags: [tag_ff14],
    year: 2022,
  },
  {
    text: "Lala Trophy",
    imageSrc: trophy,
    tags: [tag_ff14],
    year: 2022,
  },
  {
    text: "Travers' WoL",
    imageSrc: Travers,
    tags: [tag_ff14],
    year: 2022,
  },
  {
    text: "Wilson in Pokemon",
    imageSrc: wilsonPokemon,
    year: 2022,
  },
  {
    text: "Bee",
    imageSrc: Bee,
    tags: [tag_yugioh],
    year: 2022,
  },
];
export default items;
export type { GalleryItemProps };
